const kreyol = [
    "kenbe",
    "vilaj",
    "echèk",
    "lakay",
    "anyen",
    "souri",
    "espès",
    "majik",
    "mouch",
    "admèt",
    "kòman",
    "zwazo",
    "respè",
    "kapab",
    "kanpe",
    "demen",
    "limyè",
    "mitan",
    "anfòm",
    "kònen",
    "leson",
    "klere",
    "okipe",
    "solèy",
    "avoka",
    "maten",
    "klima",
    "danse",
    "bagay",
    "blese",
    "komen",
    "mirak",
    "bouch",
    "doulè",
    "vodou",
    "zanmi",
    "manke",
    "istwa",
    "mizik",
    "eseye",
    "detay",
    "drapo",
    "paske",
    "kriye",
    "lekòl",
    "kijan",
    "revèy",
    "ranje",
    "sonje",
    "lanmè",
    "matyè",
    "mande",
    "kraze",
    "ayiti",
    "kalme",
    "cheri",
    "fidèl",
    "fanmi",
    "anpil",
    "dènye",
    "babay",
    "tonbe",
    "konsa",
    "jenou",
    "manje",
    "konsè",
    "koman",
    "pawòl",
    "fache",
    "nouvo",
    "petèt",
    "lakou",
    "chaje",
    "sifle",
    "achte",
    "pwojè",
    "atire",
    "chalè",
    "arive",
    "kòrèk",
    "cheve",
    "remèd",
    "sante",
]

export default kreyol;
